import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import necessary components

import About from './components/pages/About';
import Home from './components/pages/Home';
import Location from './components/pages/Location';
import KarateClasses from './components/classes/KarateClasses';
import SkatingClasses from './components/classes/SkatingClasses';
import TennisClasses from './components/classes/TennisClasses';
import ZumbaClasses from './components/classes/ZumbaClasses';
import FootballClasses from './components/classes/FootballClasses';
import OtherClasses from './components/classes/OtherClasses';
import AboutTennis from './components/classes/AboutTennis';
import ContactPage from './components/ContactPage';
import AboutFootball from './components/classes/AboutFootball';
import CricketClasses from './components/classes/CricketClasses';
import AboutCricket from './components/classes/AboutCricket';
import BasketballClasses from './components/classes/BasketballClasses';
import AboutBasketball from './components/classes/AboutBasketball';
import AboutSkating from './components/classes/AboutSkating';
import AboutKarate from './components/classes/AboutKarate';
import AboutZumba from './components/classes/AboutZumba';
import AboutFitnessAthletic from './components/classes/AboutFitnessAthletic';
import FitnessAthleticClasses from './components/classes/FitnessAthleticClasses';
import HealthHub from './components/pages/HealthHub';
import WellMaintained from './components/pages/WellMaintained';

import TandC from './components/pages/TandC';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import Faqs from './components/pages/Faqs';
import RefundPolicy from './components/pages/RefundPolicy';
import NewsBlogs from './Newsblogs/NewsBlogs';






function App() {
  return (
    <React.Fragment>
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/location" element={<Location />} />
        <Route path="/contactpage" element={<ContactPage />} />
        <Route path="/healthhub" element={<HealthHub />} />
        <Route path="/wellmaintained" element={<WellMaintained />} />
        
        <Route path="/tandc" element={<TandC />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />


        <Route path="/larateclasses" element={<KarateClasses />} />
        <Route path="/skatingclasses" element={<SkatingClasses />} />
        <Route path="/tennisclasses" element={<TennisClasses />} />
        <Route path="/zumbaclasses" element={<ZumbaClasses />} />
        <Route path="/footballclasses" element={<FootballClasses />} />
        <Route path="/otherclasses" element={<OtherClasses />} />
        <Route path="/karateclasses" element={<KarateClasses />} />
        <Route path="/cricketclasses" element={<CricketClasses />} />
        <Route path="/basketballclasses" element={<BasketballClasses />} />

        <Route path="/fitnessathleticclasses" element={<FitnessAthleticClasses />} />
        {/* <Route path="/basketballclasses" element={<BasketballClasses />} /> */}

        <Route path="/abouttennis" element={<AboutTennis />} />
        <Route path="/aboutfootball" element={<AboutFootball />} />
        <Route path="/aboutcricket" element={<AboutCricket />} />
        <Route path="/aboutbasketball" element={<AboutBasketball />} />
        <Route path="/aboutskating" element={<AboutSkating />} />
        <Route path="/aboutkarate" element={<AboutKarate />} />
        <Route path="/aboutzumba" element={<AboutZumba />} />
        <Route path="/aboutfitnessathletic" element={<AboutFitnessAthletic />} />
        
        
        <Route path="/newsblogs" element={<NewsBlogs />} />
        </Routes>
    </React.Fragment>
  );
}

export default App;
